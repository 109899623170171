import React from "react";


function ShortDescriptionStatic() {
    return (
        <div className="description-static description"> 
            <p>
                Hello! I'm Pedro, an electrical engineer.
            </p>
            <p>
                I have a passion for creating things and solving problems, particularly in the fields of robotics, space exploration, and embedded systems.
            </p>
            <p>
                This page is under construction. Please check back soon!
            </p>
        </div>
    );
}

export { ShortDescriptionStatic };
