import React from 'react';

function HorizontalSeparator() {
    return (
        <div style={{
            height: '4px',
            backgroundColor: 'black',
            width: '100%',
        }}></div>
    );
}

export { HorizontalSeparator };
