import React from 'react';

import * as THREE from 'three';
import NET from 'vanta/dist/vanta.net.min';
// import WAVES from 'vanta/dist/vanta.waves.min';
// import BIRDS from 'vanta/dist/vanta.birds.min';

function VantaJsBackground() {
    const [vantaEffect, setVantaEffect] = React.useState<any>(null)
    const vantaRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        if (!vantaEffect) {
            setVantaEffect(NET({
                el: vantaRef.current,
                THREE: THREE,
                mouseControls: true,
                touchControls: true,
                scale: 1.00,
                scaleMobile: 1.00,
                scrollOptions: {
                    speed: 0.5,
                },
            }));
        }
        return () => {
            if (vantaEffect) vantaEffect.destroy();
        }
    }, [vantaEffect]);

    return (
        <div ref={vantaRef} style={{ 
            position: 'fixed', 
            top: 0, 
            left: 0, 
            zIndex: -1, 
            width: '100%', 
            height: '100%',
            // do not allow scrolling
            overflow: 'hidden',
            display: 'inline-block',
        }}>
        </div>
    )
}

export { VantaJsBackground };
