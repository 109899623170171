import React, { ReactComponentElement } from "react";
import { HorizontalSeparator } from "./HorizontalSeparator.tsx";

import './shared.css';

enum ProjectCardTextColor {
    RED = "rgba(255, 200, 200, 0.3)",
    GREEN = "rgba(200, 255, 200, 0.3)",
    BLUE = "rgba(200, 200, 255, 0.3)",
    SELECTED = "rgba(255, 255, 255, 0.8)",
}

interface ProjectProps {
    title: string;
    id: string;
    shortDescription: string;
    longDescription: ReactComponentElement<any>;
    href: string;
    image: string;
}
function ProjectCard(props: ProjectProps) {
    var [isVisible, setVisible] = React.useState<boolean>(false);
    var [isClicked, setClicked] = React.useState<boolean>(false);
    const domRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        if (!domRef.current) return;
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        observer.observe(domRef.current as Element);
        const domRefCur = domRef.current;
        return () => observer.unobserve(domRefCur as Element);
    }, []);
    return (
        <div 
            onClick={() => setClicked(!isClicked)}
            className={"project-card " + (isVisible ? "fade-in":"invis")} ref={domRef}
            style={{
                color: ProjectCardTextColor.SELECTED,
                opacity: isVisible ? 1 : 0,
            }}
        >
            <a href={"#" + props.id}
            style={{
                textDecoration: "none",	
                color: "inherit",
            }}>
                <div className="project-card-title">
                    {props.title}
                </div>
                <p className="project-card-description"
                    style={{
                        }}>
                    {props.shortDescription}
                </p>
            </a>
        </div>
    );
}

function Project(props: ProjectProps) {
    var [isVisible, setVisible] = React.useState<boolean>(false);
    // variable to store this project's ref
    const projectRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        if (!projectRef.current) return;
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        observer.observe(projectRef.current as Element);
        const projectRefCur = projectRef.current;
        return () => observer.unobserve(projectRefCur as Element);
    }, []);
    const imageContent = props.image ?
        <a href={props.href} className="project-link">
            <img src={props.image} alt={props.title} className="project-image" />
        </a>
        : <div></div>;

    return (
        <div 
            className={"project-long " + (isVisible ? "fade-in":"invis")}
            style={{
                borderRadius: "1rem",
                color: ProjectCardTextColor.SELECTED,
                opacity: isVisible ? 1 : 0,
            }}
            ref={projectRef}
        >
            <div className="project-title">
                {props.title}
            </div>
            <div className="project-description">
                <em>
                    {props.shortDescription}
                </em>
            </div>
            <div className="project-long-description">
                {props.longDescription}
            </div>
            {imageContent}
        </div>
    );
}

function Projects() {
    const projects: ProjectProps[] = [
        {
            title: "Bus Schedule Search Tool",
            id: "horarios",
            shortDescription: "The first publicly available tool capable of searching all the different bus lines for a single trip (pair of bus stops), for the Carris Metropolitana bus routes.",
            longDescription: <div>
                <p>
                    Created to help regular bus users transition to the recently established Carris Metropolitana, a new public transport company that unified road operations replacing many smaller operators in the Lisbon district.
                </p>
                <p>
                    Built by scraping the official bus schedules, storing them in a relational database and using a custom algorithm to search for the best route.
                </p>
                <p>
                    The tool has since been deprecated but is available at <a href="https://horarios.pedrotaborda.me/">https://horarios.pedrotaborda.me/</a>
                </p>
                <p>
                    Built using Flask and SQLite. Raw html, css and js (with jquery) for the frontend.
                    Hosted on a VPS, managed with nginx.
                </p>
            </div>,
            href: "https://horarios.pedrotaborda.me/",
            image: "preview_horarios.png",
        },
        {
            title: "Coalesce",
            id: "coalesce",
            shortDescription: "A card game with simple rules built over a few days by four people, with the goal of creating a fun and engaging game that can be played anywhere.",
            longDescription: <div>
                <p>
                    Designed as a fun team activity for a group of friends, the game is a mix of strategy and luck,
                    with a simple set of (not original) rules.
                </p>
                <p>
                    The game is played in the browser, with any device and number of players (up to 100). <br />
                    Its development was mostly a social endeavor, but it was also a great opportunity to learn about
                    multiplayer game development. I handled the backend and still maintain the game, having
                    added a few features since its initial release. The backend mostly involved learning about websockets and
                    concurrency in python, given that it's written in Flask.
                    Hosting is done on a VPS, managed with nginx.
                    <br />
                    The game is available at <a href="https://coalesce.pedrotaborda.me/">https://coalesce.pedrotaborda.me/</a>
                </p>
            </div>,
            href: "https://coalesce.pedrotaborda.me/",
            image: "preview_coalesce.png",
        },
        {
            title: "Controller for Spacecraft Rendezvous",
            id: "mpc-rendezvous",
            shortDescription: "A project developed for my Master's thesis, involving the development of a controller capable of performing a rendezvous maneuver into an orbiting target.",
            longDescription: <div>
                <p>
                    A simplified spacecraft, with the knowledge of its own position and velocity, and the target orbit, along with knowledge of its own thrusters, equipped with the controller 
                    developed in this project, is capable of autonomously performing a rendezvous maneuver from over 100km away, to a few meters away from the target.
                    <br />
                    The science behind this is better explained in my Master's dissertation, to become available <a href="https://fenix.tecnico.ulisboa.pt/cursos/meec21/dissertacao/1128253548923775">here</a>,
                    so I will not go into detail here.
                    The code is fully available at <a href="https://github.com/PedroTaborda/mpc-for-rendezvous">https://github.com/PedroTaborda/mpc-for-rendezvous</a>,
                    and is written in <a href="https://www.mathworks.com/products/matlab.html">Matlab</a>, 
                    using the <a href="https://www.gurobi.com/solutions/gurobi-optimizer/">Gurobi Optimizer</a> for
                    the optimization heavy lifting, required the Model Predictive Control algorithm.
                    <br />
                    The project was developed with the help of my advisor, Prof. Daniel Silvestre, and the collaboration of Dr. Pedro Lourenço from <a href="https://www.gmv.com/">GMV</a>.
                </p>
            </div>,
            href: "https://github.com/PedroTaborda/mpc-for-rendezvous",
            image: "rendezvous.jpg",
        },
        {
            title: "Education",
            id: "education",
            shortDescription: "Master's in Electrical and Computer Engineering at Instituto Superior Técnico, Lisbon, Portugal. Thesis: 'Model Predictive Control and Allocation with Nonconvex Constraints for Space Rendezvous'.",
            longDescription: <div>
                <p>
                    I have recently completed my Master's degree in Electrical and Computer Engineering at Instituto Superior Técnico, after having
                    completed my Bachelor's degree in the same institution. <br />
                    This was a 5 year undertaking, from 2018 to 2023.
                    I ended up specializing in Control Systems and Robotics, and my thesis was about using Model Predictive Control in Space Rendezvous, focusing on
                    the problem of nonconvex constraints introduced by the physics of the thrusters.
                </p>
                <p>
                    Finished with a 18/20 grade. Received a few diplomas for academic performance.
                </p>
                <ol>
                    <li>
                        <a href="https://files.pedrotaborda.me/academic/18-19-merit-PT.pdf">2018/2019 Merit Diploma</a>
                    </li>
                    <li>
                        <a href="https://files.pedrotaborda.me/academic/19-20-excellence-EN.pdf">2019/2020 Excellence Diploma</a>
                    </li>
                    <li>
                        <a href="https://files.pedrotaborda.me/academic/20-21-merit-EN.pdf">2020/2021 Merit Diploma</a>
                    </li>
                    <li>
                        <a href="https://files.pedrotaborda.me/academic/21-22-merit-EN.pdf">2021/2022 Merit Diploma</a>
                    </li>
                </ol>
                <p>
                    This is the foundation of my technical knowledge, and it has given me a strong background in mathematics, physics, and computer science.
                    <br />
                    The first three years made up the Bachelor's degree, with a strong focus on the fundamentals of electrical and computer engineering.
                    This included courses on calculus, linear algebra, mechanics, electromagnetism, electronics, digital systems, programming, and algorithms.
                    The last two years were much more specializing, with courses on control systems, robotics, machine learning, and optimization.
                    The final project was a dissertation, which I chose to do in the field of control systems within the space industry.
                    <br />
                    I believe this course provided me with strong fundamentals, 
                    and the projects I have worked on both in and out of my academic path
                    have given me some practical experience.
                </p>
            </div>,
            href: "https://tecnico.ulisboa.pt/",
            image: "IST.jpg",
        },
        {
            title: "This Website",
            id: "this-website",
            shortDescription: "The website you are currently visiting. Built using React (Typescript), Material-UI, Vanta.js. Hosted using Node.js on a VPS, managed with nginx.",
            longDescription: <div>
                <p>
                    This website was built as a way to learn about modern web development, and to have a place to showcase my work and interests.
                    <br />
                    I do not have a formal education in web development, having previously only built a few small projects and websites, mostly using Flask, 
                    which allowed me to learn about the basics of web development, server and domain management. All my websites are hosted on a VPS,
                    on different ports. Whatever URL you use to access my websites all go to the same server. These requests
                    are then routed to the correct port using nginx. 'Coalesce', for example, is hosted on port 8000, making it accessible at not only
                    at <a href="https://coalesce.pedrotaborda.me/">https://coalesce.pedrotaborda.me/</a> but also at <a href="https://pedrotaborda.me:8000">https://pedrotaborda.me:8000</a> (although 
                    I have not gone through the trouble of setting up SSL encryption for the latter).
                    Only the websites where no sensitive data is exchanged have been kept open to direct access through the port.
                    <br />
                    Most of what I wanted to show here would fit on a wall of text, which this website mostly is, but I also wanted it not to feel
                    outdated or dull. The animated background and the fading in of some elements are some of the ways I tried to make it more engaging.
                    Web development is a field I have dabbled in to expose some of my ideas and make them more accessible, 
                    but it is not at all my main focus or area of expertise.
                </p>
            </div>,
            href: "https://www.google.com",
            // image: "https://via.placeholder.com/150",
            image: "",
        },
    ];

    return (
        <div className="projects-display">
            <div className="projects-display-short" id="portfolio">
                <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                    <div className="project-cards">
                        {projects.map((project) => {
                            return <div key={project.id}
                                style={{
                                    display: 'inline-block',
                                }}
                            > 
                                <ProjectCard {...project}/>
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <div className="projects-display-long">
                {projects.map((project) => {
                    return (
                        <div className="project" id={project.id} key={project.id}>
                            <HorizontalSeparator />
                            <Project {...project}/>
                        </div>
                    ) 
                })}
            </div>
        </div>
    );
}

export { Projects };

