import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import * as React from 'react';
import { VantaJsBackground } from './components/VantaJsBackground.tsx';
import { ShortDescriptionStatic } from './components/ShortDescriptionStatic.tsx';
import { NavBar } from './components/NavBar.tsx';
import { Projects } from './components/Projects.tsx';
import { HorizontalSeparator } from './components/HorizontalSeparator.tsx';

function App() {
    return (
        <div>
            <VantaJsBackground />
            <div id="landing" style={{ 
                height: '100vh',
            }}>
                <NavBar />
            </div>
            <ShortDescriptionStatic />
            <HorizontalSeparator />
            <Projects />
            <HorizontalSeparator />

        </div>
    )
}

export default App;
